import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/utils/loadElementui'
import './style/common.scss'

// 引入 app-element
import { plugins } from 'app-element'

Vue.config.productionTip = false
Vue.prototype.pre = 'sxw'
Vue.use(plugins)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
